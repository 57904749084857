import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import ShorterContent from './ShorterContent'

export default class Sector extends React.Component {
    state = {
        sector: this.props.content && this.props.content[0],
        company: null,
    }

    handleSectorClick = (clicked) => {
        this.setState({ sector: clicked })
    }

    toggleLogo = (clicked) => {
        if (clicked == this.state.company) {
            this.setState({ company: null })
            return
        }
        this.setState({ company: clicked })
    }

    componentDidMount() {
        // let b = document.getElementsByClassName("progress")
        // let i = 0;
    
        // for(const sector in this.props.content){
        //     let degrees = this.props.content[sector].percentage * 3.6;
        //     if(degrees>180) {
        //         b[i+1].style.transform = (degrees==360) ? `rotate(180deg)` : `rotate(${degrees%180}deg)`;
        //         degrees = (degrees==360) ? 180 : (degrees - (degrees%180));
        //     }
            
        //     b[i].style.transform = `rotate(${degrees}deg)`;
        //     i = i+2;
        // }
      }

    render() {
        const { content } = this.props
        let all = []

        for(const sector in content){
            all = all.concat(content[sector].company)
        }

        return (
            <div>
                {/* <section className="gray-background grid flex">
                    <div className="sector">
                        {content && content.map(sector => (
                            <button onClick={() => this.handleSectorClick(sector)} className={`sector__header ${this.state.sector == sector && 'sector__expanded'}`}>
                                <div className='flex flex--column flex--v-center'>
                                <div class="circular margin-bottom-small">
                                    <div class="inner"></div>
                                    <div class="number">{sector.percentage} %</div>
                                    <div class="circle">
                                        <div class="bar left">
                                            <div class="progress"></div>
                                        </div>
                                        <div class="bar right">
                                            <div class="progress"></div>
                                        </div>
                                    </div>
                                </div>
                                    <strong className='smaller'>{sector.title}</strong>
                                    <FontAwesomeIcon icon={sector == this.state.sector ? faChevronUp : faChevronDown} />
                                </div>
                            </button>
                        ))}
                    </div>
                </section> */}

                <div className="h">
                    <ShorterContent>
                        {/* {this.state.sector && <div dangerouslySetInnerHTML={{
                            __html: this.state.sector.description,
                        }} />} */}
                        {all && all.map(comp => (
                            <div className='margin-top-small flex flex--column flex--v-center company'>
                                <button onClick={() => this.toggleLogo(comp)} className="margin-top-big flex flex--column flex--v-center">
                                    {comp.image && <img src={comp.image.fixed.src} />}
                                    <div className='margin-top-small' dangerouslySetInnerHTML={{
                                        __html: comp.description,
                                    }} />
                                </button>
                                {this.state.company == comp &&
                                    <div className='flex flex--column company__expanded prose'>
                                        <div className='flex'>
                                            <div className='small margin-bottom-big margin-right-big' dangerouslySetInnerHTML={{
                                                __html: comp.detailedInformation,
                                            }} />
                                            <div className='small prose'
                                                dangerouslySetInnerHTML={{
                                                    __html: comp.companyFacts,
                                                }} />
                                        </div>
                                        {comp.link && comp.link.map(aLink => (<a className="btn portfolio-link margin-top-small" target="_blank" href={aLink.target}>{aLink.name} <FontAwesomeIcon className='margin-left-small' icon={faArrowRight}/></a>))}
                                    </div>
                                }
                                <button onClick={() => this.toggleLogo(comp)}><FontAwesomeIcon className='margin-top-small' icon={comp == this.state.company ? faChevronUp : faChevronDown} /></button>
                            </div>
                        ))}
                    </ShorterContent>
                </div>
            </div>
        )
    }
}
