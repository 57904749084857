import React from "react"
import { graphql } from 'gatsby'
import LocaleContext from '../../contexts/LocaleContext'
import Layout from '../../components/Layout'
import PortfolioLayout from '../../components/PortfolioLayout'
import Sector from '../../components/Sector'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'
import ShorterContent from '../../components/ShorterContent'

export const query = graphql`
  query ($locale: String) {
    datoCmsNewMenu ( locale: { eq: $locale}) {
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale}) {
      ...Footer
    }
    datoCmsPortfolio ( locale: { eq: $locale}) {
      intro
      sector {
        title
        percentage
        description
        company {
          name
          description
          image {
            fixed(width: 150, height: 150,) {
              ...GatsbyDatoCmsFixed
            }
          }
          detailedInformation
          companyFacts
          link {
            name
            target
          }
        }
      }
    }
  }
  `

export default function Portfolio({ data, pageContext }) {
  const { intro, sector,  } = data.datoCmsPortfolio

  return (
    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
        <Menu
          transparent={true}
          menu={data.datoCmsNewMenu}
          socialMedia={data.datoCmsFooter.socialMedia}
          />
          <PortfolioLayout>
          <section className="container center-width prose">
            <ShorterContent>
              <div dangerouslySetInnerHTML={{
                __html: intro,
              }}></div>
              <Sector content={sector}  />
            </ShorterContent>
          </section>
        </PortfolioLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}
